<template>
	<div class="page-content">
		<!-- <div class="park-form">
			<el-form inline label-position="top" ref="searchForms" style="display: flex;flex-wrap: wrap">
				<el-form-item label="卡号" prop="card_num">
					<el-input v-model="searchForm.card_num" style="width: 220px;" clearable placeholder="请填写"></el-input>
				</el-form-item>
				<el-form-item style="margin-top: auto;margin-right: auto;">
					<div class="fill-btn" @click="getList">查询</div>
				</el-form-item>
				<el-form-item style="margin-top: auto;margin-left: 20px;margin-right: 0;">
					<div class="fill-btn" @click="handShowAdd">新增</div>
				</el-form-item>
			</el-form>
		</div> -->
		<div class="table-content">
			<div class="table-top">
				<div class="table-top-text">
					<!--已选择{{selectData.length}}项-->
				</div>
			</div>
			<el-table :data="tableData" stripe v-loading="loading">
				<el-table-column prop="id" label="ID" min-width="60px">
					<template slot-scope="{row}">
						{{row.is_vip==2?row.special_card:row.normal_card}}
					</template>
				</el-table-column>
				<el-table-column prop="phone" label="手机号" min-width="120px"></el-table-column>
				<el-table-column prop="nickname" label="昵称" min-width="120px"></el-table-column>
				<el-table-column prop="head" label="头像" min-width="60px">
					<template slot-scope="{row}">
						<el-image style="width: 40px; height: 40px" :src="row.head" :preview-src-list="[row.head]">
						</el-image>
					</template>
				</el-table-column>
				<el-table-column prop="num_all" label="成就总数" min-width="120px"></el-table-column>
				<!-- <el-table-column label="成就" width="300px">
					<template slot-scope="{row}">
						<div class="f-s-c">
							<div class="block" v-if="row.end_one_title">
							    <div class="demonstration">{{ row.end_one_title }}</div>
								<el-image style="width: 40px; height: 40px" :src="row.end_one_image" :preview-src-list="[row.end_one_image]"></el-image>
							</div>
							<div class="block" v-if="row.end_two_title">
							    <div class="demonstration">{{ row.end_two_title }}</div>
								<el-image style="width: 40px; height: 40px" :src="row.end_two_image" :preview-src-list="[row.end_two_image]"></el-image>
							</div>
							<div class="block" v-if="row.end_three_title">
							    <div class="demonstration">{{ row.end_three_title }}</div>
								<el-image style="width: 40px; height: 40px" :src="row.end_three_image" :preview-src-list="[row.end_three_image]"></el-image>
							</div>
							<div class="block" v-if="row.num_all>3">
								...
							</div>
						</div>
					</template>
				</el-table-column> -->
			</el-table>
			<div class="pagination-content">
				<el-pagination background :current-page.sync="searchForm.page" :page-size="searchForm.limit"
					@current-change="searchForm.page=$event" @size-change="handleSizeChange"
					layout="total, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		delToast,
		formVerify
	} from './../../utlis/decorators'
	export default {
		name: "rankingList",
		data() {
			return {
				loading: false,
				total: 0,

				searchForm: {
					page: 1,
					limit: 10,
				},
				tableData: [],
			}
		},
		created() {
			this.getList();
		},
		watch: {
			'searchForm.page'() {
				this.getList();
			},
		},
		methods: {
			//页面数量改变
			handleSizeChange(val) {
				this.searchForm.page = 1;
				this.searchForm.limit = val;
			},
			//获取列表
			async getList() {
				this.loading = true
				let [e, data] = await this.$api.getAchievementRankApi(this.searchForm);
				this.loading = false
				if (e) return
				if (data.code === 200){
					this.tableData = data.data.data;
					this.total = data.data.total;
				}
			},
		}
	}
</script>
<style scoped lang="less">
	.f-s-c{
		display: flex;
		.block{
			width: 90px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
	}
	
</style>
